import { toError } from '@asta/lib'

// Used to retry a function a few times
// Only used to attempt to get the project item id
// This is because sometimes the project item id is not immediately available
export const retry = async <T,>(
	fn: () => Promise<T>,
	retries: number,
	delay: number
): Promise<T> => {
	let lastError: Error | unknown
	for (let i = 0; i < retries; i++) {
		try {
			const result = await fn()
			if (result) {
				return result
			}
		} catch (error) {
			lastError = toError(error)
			await new Promise(resolve => setTimeout(resolve, delay))
		}
	}
	lastError ??= new Error(
		`Failed to get project item id after ${retries} retries`
	)
	throw lastError
}

export function getBrowserName(userAgent: string | string[]): string {
	if (userAgent.includes('Firefox')) {
		return 'Mozilla Firefox'
	} else if (userAgent.includes('SamsungBrowser')) {
		return 'Samsung Internet'
	} else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
		return 'Opera'
	} else if (userAgent.includes('Edge')) {
		return 'Microsoft Edge (Legacy)'
	} else if (userAgent.includes('Edg')) {
		return 'Microsoft Edge (Chromium)'
	} else if (userAgent.includes('Chrome')) {
		return 'Google Chrome or Chromium'
	} else if (userAgent.includes('Safari')) {
		return 'Apple Safari'
	} else {
		return 'unknown'
	}
}
