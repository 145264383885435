import React, { useContext } from 'react'

import { ErrorBoundary } from '@asta/react-component-library'
import CloseIcon from '@mui/icons-material/Close'
import ReplayIcon from '@mui/icons-material/Replay'
import {
	Alert,
	Box,
	Button,
	IconButton,
	Modal,
	Typography,
} from '@mui/material'
import { styled } from '@mui/system'

import { FormGenerator } from './FormGenerator'
import { ReporterContext } from './ReporterContext'

export interface IssueReporterProps {
	hideDiv: () => void
	position: { x: number; y: number }
}

const StyledModal = styled(Modal)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	overflow: 'hidden',
	height: '100%',
	pointerEvents: 'none',
})

const ContentBox = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '90%',
	maxWidth: '700px',
	backgroundColor: theme.palette.background.paper,
	border: '2px solid #000',
	maxHeight: '80vh',
	overflowY: 'auto',
	pointerEvents: 'auto',
}))

const Header = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '16px',
	borderBottom: '1px solid #ddd',
	position: 'sticky',
	top: 0,
	backgroundColor: 'inherit',
	zIndex: 999,
})

const RetryButton = styled(Button)({
	display: 'flex',
	alignItems: 'center',
})

interface IssueFormProps {
	isCompanion: boolean
}

const imageStyle = { maxWidth: '100%', height: 'auto' }

export const IssueForm: React.FC<IssueFormProps> = ({ isCompanion }) => {
	const context = useContext(ReporterContext)

	if (!context) {
		return (
			<Typography color="error">
				Context is not available please try refreshing the page
			</Typography>
		)
	}

	const {
		fetchError,
		retryQueries,
		appContext,
		reporterVisible,
		hideReporter,
		selectEnabled,
		startHighlighting,
		stopHighlighting,
		formType,
	} = context
	const errorMessage = (
		<Box>
			<Alert severity={fetchError?.severity}>{fetchError?.message}</Alert>
			<RetryButton
				variant="contained"
				color="primary"
				onClick={retryQueries}
				startIcon={<ReplayIcon />}
			>
				Retry
			</RetryButton>
		</Box>
	)

	return (
		<StyledModal
			disablePortal={true}
			open={reporterVisible}
			onClose={hideReporter}
			disableScrollLock={true}
		>
			<ContentBox m={20} borderRadius={5}>
				<Header>
					<Typography variant="h5">
						{formType === 'applicationBugReport'
							? 'Application Bug Report'
							: 'Asta Feedback'}
					</Typography>
					<Button
						variant="contained"
						color={selectEnabled ? 'secondary' : 'primary'}
						onClick={
							selectEnabled ? stopHighlighting : startHighlighting
						}
					>
						{selectEnabled
							? 'Stop Highlighting'
							: 'Highlight Element'}
					</Button>
					<IconButton onClick={hideReporter}>
						<CloseIcon />
					</IconButton>
				</Header>
				<Box p={3}>
					{fetchError?.severity === 'error' && errorMessage}
					<ErrorBoundary>
						{fetchError?.severity !== 'error' && (
							<>
								{fetchError?.severity === 'warning' &&
									errorMessage}
								<FormGenerator
									hideDiv={hideReporter}
									isCompanion={isCompanion}
								/>
							</>
						)}
					</ErrorBoundary>
					{appContext && (
						<div>
							<Typography variant="h6">Screenshot</Typography>
							<img
								src={appContext.screenshot}
								alt="Screenshot"
								style={imageStyle}
							/>
						</div>
					)}
				</Box>
			</ContentBox>
		</StyledModal>
	)
}
