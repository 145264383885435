import { actions, IssueState } from './IssueReporterReducer'
import {
	getProjectItemId,
	postIssueToGitHub,
	ProjectV2FieldValue,
	updateIssueOnGitHub,
	updateProjectFields,
} from './reporter-api-caller'
import { FormField, projectIndex } from './ReporterContext'
import { retry } from './utils'

export const onSubmit = async (
	event: React.FormEvent,
	formData: IssueState,
	startIndexProjectFields: number,
	endIndexProjectFields: number,
	openSnackbar: () => void,
	issueFormActions: typeof actions,
	hideDiv: () => void,
	showReporter: () => void
): Promise<void> => {
	event.preventDefault()
	formData.validateForm
	if (formData.errors['title'] || formData.errors['description']) {
		openSnackbar()
		return
	}
	// Optimistically hide the form and show a success snackbar
	issueFormActions.formSubmissionSuccess()
	openSnackbar()
	hideDiv()

	// Store a copy of the current form state in case we need to revert
	const previousFormData = { ...formData }

	try {
		if (formData.isUpdateMode) {
			await updateIssueOnGitHub(
				formData.issueNumber,
				formData.loadedTitle,
				formData.loadedDescription
			)

			const fieldUpdates = formData.fields
				.slice(startIndexProjectFields, endIndexProjectFields)
				.map(field => createFieldUpdate(field, formData))
				.filter(update => Object.keys(update.value).length > 0)

			await updateProjectFields(
				formData.projectItemId,
				formData.projects[projectIndex].id,
				fieldUpdates
			)
		} else {
			const issueResponse = await postIssueToGitHub(
				formData.title,
				formData.description
			)

			const issueNodeId = issueResponse.node_id
			const projectItemId = await retry(
				() => getProjectItemId(issueNodeId),
				5,
				2000
			)

			const fieldUpdates = formData.fields
				.slice(startIndexProjectFields, endIndexProjectFields)
				.map(field => createFieldUpdate(field, formData))
				.filter(update => Object.keys(update.value).length > 0)

			await updateProjectFields(
				projectItemId,
				formData.projects[projectIndex].id,
				fieldUpdates
			)
		}

		issueFormActions.reset()
		// Final success action if the request was successful
		issueFormActions.formSubmissionSuccess()
	} catch (error) {
		// Rollback optimistic update if the request fails
		issueFormActions.setFormState(previousFormData)
		openSnackbar()
		showReporter()
		console.error('Error posting/updating issue on GitHub', error)
		issueFormActions.formSubmissionFailure(
			'Error posting/updating issue on GitHub'
		)
	} finally {
		openSnackbar()
	}
}

export const createFieldUpdate = (
	field: FormField,
	formData: IssueState
): { fieldId: string; value: ProjectV2FieldValue } => {
	const value: ProjectV2FieldValue = {}
	const fieldValue = formData[field.name] || ''
	const option = field.options?.find(option => option.name === fieldValue)
	if (fieldValue !== '') {
		if (option) {
			value.singleSelectOptionId = option.id
		} else if (field.dataType === 'NUMBER') {
			value.number = parseFloat(fieldValue as string)
		} else if (field.configuration) {
			value.iterationId = fieldValue as string
		} else {
			value.text = fieldValue as string
		}
	}
	return {
		fieldId: field.id,
		value,
	}
}
