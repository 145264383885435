import { FormControl, SelectChangeEvent, TextField } from '@mui/material'

import { renderConfigField } from './FormGenerator'
import { IssueState } from './IssueReporterReducer'
import { ConfigField } from './ReporterContext'
import RenderSelectField from './SelectField'

const inputProps = {
	readOnly: true,
}

export const CompanionForm: React.FC<{
	field: ConfigField
	formData: IssueState
	onInputChange: React.ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	>
	onSelectChange: (event: SelectChangeEvent<string>) => void
	customOptions: string[]
}> = ({ field, formData, onInputChange, onSelectChange, customOptions }) => {
	if (field.name === 'Requested By') {
		return (
			<FormControl fullWidth margin="normal">
				<TextField
					name={field.name}
					label={field.name}
					value="Customer"
					onChange={onInputChange}
					required
					InputProps={inputProps}
				/>
			</FormControl>
		)
	}

	if (field.name === 'Type') {
		return (
			<RenderSelectField
				field={field}
				errors={formData.errors}
				fieldData={formData.fields[field.id]}
				formData={formData}
				onSelectChange={onSelectChange}
				defaultValue="Bug"
				customOptions={customOptions}
			/>
		)
	}

	return renderConfigField(
		field,
		formData.fields[field.id],
		formData,
		onInputChange,
		onSelectChange
	)
}
