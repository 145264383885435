import 'shepherd.js/dist/css/shepherd.css'

import { ReporterApp } from '@asta/chrome-extension'
import { ReporterContextProvider } from '@asta/chrome-extension/content-script/src/IssueWidget/ReporterContext'
import {
	ColorSchemeProvider,
	ErrorBoundary,
	ThemeCustomizationWrapper,
} from '@asta/react-component-library'
import Snackbar from 'components/@extended/Snackbar'
import { AuthProvider } from 'contexts/auth'
import { BreadcrumbsProvider } from 'contexts/breadcrumbs'
import { SelectedAppProvider } from 'contexts/selectedApp'
import { WorkspaceProvider } from 'contexts/workspace'
import RTLLayout from 'deprecated/components/RTLLayout'
import ScrollTop from 'deprecated/components/ScrollTop'
import ErrorPage from 'pages/ErrorPage'
import { Toaster } from 'react-hot-toast'
import Routes from 'routes'
import ThemeCustomization from 'themes'

// If the issue reporter completely fails, we don't want to show an error div
// to the user. Instead, we want to silently fail.
const SilentFallback: React.FC = () => null

const App = (): JSX.Element => {
	return (
		<ColorSchemeProvider>
			<RTLLayout>
				<ThemeCustomization>
					<ThemeCustomizationWrapper>
						<AuthProvider>
							<WorkspaceProvider>
								<SelectedAppProvider>
									<ScrollTop>
										<BreadcrumbsProvider>
											<ReporterContextProvider
												isCompanion
											>
												<ErrorBoundary
													Fallback={ErrorPage}
												>
													<Toaster />
													<Routes />
													<Snackbar />
													<ErrorBoundary
														Fallback={
															SilentFallback
														}
													>
														<ReporterApp
															isCompanion
														/>
													</ErrorBoundary>
												</ErrorBoundary>
											</ReporterContextProvider>
										</BreadcrumbsProvider>
									</ScrollTop>
								</SelectedAppProvider>
							</WorkspaceProvider>
						</AuthProvider>
					</ThemeCustomizationWrapper>
				</ThemeCustomization>
			</RTLLayout>
		</ColorSchemeProvider>
	)
}

export default App
