import React, {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import companionPackage from '@asta/companion/package.json'
import { useAstaAuth } from '@asta/companion/src/contexts/auth/useAuth'
import { Point } from '@asta/lib'
import { useToggle } from '@asta/react-component-library'
import { RunLogEntry } from '@asta/run-artifacts/dist/esm/run-log/entry/run-log-entry'
import { SelectChangeEvent } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import html2canvas from 'html2canvas'

import formConfig from '../../../public/formConfig.json'
import {
	actions,
	fetchProjectFields,
	fetchProjects,
	IssueState,
	loadIssueData,
	reporterInitialState,
	useIssueFormReducer,
} from './IssueReporterReducer'
import { getGithubConfig } from './reporter-api-caller'
import { FetchError, Issue, Position } from './types'
import { getBrowserName } from './utils'

type AppContext = {
	url: string
	screenshot: string
	environment: string
	date: string
	astaVersion: string
}

type UserContext = {
	browserVersion: string
	username: string
}

export const ZERO: Point = { x: 0, y: 0 }
export const StartingPos: Point = { x: 0, y: 60 }
export const projectIndex: number = formConfig.forms[0].projectIndex ?? 0

// Used to prevent the portal from rendering
// Outside the root component
export const menuProps = {
	disablePortal: true,
}

type ReporterContextType = {
	issue: Issue
	setIssue: React.Dispatch<React.SetStateAction<Issue>>
	pos: Position
	captureScreenshot: () => void
	captureScreenshotElement: (elementToScreenshot: HTMLElement) => void
	screenshotRef: React.RefObject<HTMLAnchorElement | null>
	selectedElements: HTMLElement[]
	setSelectedElements: React.Dispatch<React.SetStateAction<HTMLElement[]>>
	onInputChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void
	onSelectChange: (event: SelectChangeEvent<string>) => void
	formData: IssueState
	issueFormActions: typeof actions
	onInputChangeIssueNumber: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void
	onModeSwitch: () => void
	startIndexProjectFields: number
	endIndexProjectFields: number
	snackbarOpen: boolean
	openSnackbar: () => void
	closeSnackbar: () => void
	reporterVisible: boolean
	toggleReporter: () => void
	showReporter: () => void
	hideReporter: () => void
	issuePosition: Position
	fetchError: FetchError | null
	setFetchError: React.Dispatch<React.SetStateAction<FetchError | null>>
	retryQueries: () => void
	captureAppContext: () => Promise<{
		url: string
		screenshot: string
		environment: string
		date: string
		astaVersion: string
	}>
	captureUserContext: () => { browserVersion: string; username: string }
	appContext: AppContext | null
	userContext: UserContext | null
	handleTestCaptureContext: () => void
	isCompanion: boolean
	setFormType: (type: 'applicationBugReport' | 'astaFeedback') => void
	formType: 'applicationBugReport' | 'astaFeedback'
	showSpecificReporter: (
		type?: 'applicationBugReport' | 'astaFeedback'
	) => void
	selectEnabled: boolean
	setSelectEnabled: (state: boolean) => void
	startHighlighting: () => void
	stopHighlighting: () => void
}

export const ReporterContext = createContext<ReporterContextType | undefined>(
	undefined
)

export type ContextProps = React.PropsWithChildren<{
	isCompanion: boolean
	runNumber?: number
	entry?: RunLogEntry
}>

export const useReporterContext = (): ReporterContextType => {
	const context = React.useContext(ReporterContext)
	if (!context) {
		throw new Error(
			'useReporterContext must be used within a ReporterContextProvider'
		)
	}
	return context
}

export const ReporterContextProvider: React.FC<ContextProps> = ({
	children,
	isCompanion,
}) => {
	const [selectEnabled, setSelectEnabled] = useState(false)
	const [pos, setPos] = useState<Position>(ZERO)
	const [offset, setOffset] = useState<Position>(ZERO)
	const screenshotRef = useRef<HTMLAnchorElement | null>(null)
	const [selectedElements, setSelectedElements] = useState<HTMLElement[]>([])
	const [issue, setIssue] = useState<Issue>(reporterInitialState)
	const [formData, issueFormActions] = useIssueFormReducer()
	const startIndexProjectFields = formData.fields.findIndex(
		field => field.name === formConfig.forms[0].firstProjectFieldName
	)
	const endIndexProjectFields = formData.fields.length
	const [snackbarOpen, , openSnackbar, closeSnackbar] = useToggle()
	const [reporterVisible, toggleReporter, showReporter, hideReporter] =
		useToggle(false)
	const [issuePosition, _setIssuePosition] = useState(StartingPos)
	const [fetchError, setFetchError] = useState<FetchError | null>(null)
	const queryClient = useQueryClient()
	const [loadingIssueData, setLoadingIssueData] = useState(false)
	const [appContext, setAppContext] = useState<AppContext | null>(null)
	const [userContext, setUserContext] = useState<UserContext | null>(null)

	const startHighlighting = useCallback(() => {
		setSelectEnabled(true)
		hideReporter()
	}, [hideReporter])

	const stopHighlighting = useCallback(() => {
		setSelectEnabled(false)
		showReporter()
		// Clean up any highlights when stopping
		selectedElements.forEach(element => {
			if (element.style.border === '3px solid red') {
				element.style.border = ''
			}
		})
		setSelectedElements([])
	}, [selectedElements, showReporter])

	const handleElementHover = useCallback(
		(event: MouseEvent, action: 'add' | 'remove') => {
			if (!selectEnabled) return
			const element = event.target as HTMLElement
			if (element && element.style) {
				if (action === 'add') {
					if (element.style.border !== '3px solid red') {
						element.style.border = '3px solid blue'
					}
				} else if (action === 'remove') {
					if (element.style.border !== '3px solid red') {
						element.style.border = ''
					}
				}
				event.stopPropagation()
			}
		},
		[selectEnabled]
	)

	const handleElementMouseOver = useCallback(
		(event: MouseEvent) => handleElementHover(event, 'add'),
		[handleElementHover]
	)

	const handleElementMouseOut = useCallback(
		(event: MouseEvent) => handleElementHover(event, 'remove'),
		[handleElementHover]
	)

	const captureScreenshotElement = useCallback(
		async (elementToScreenshot: HTMLElement) => {
			const canvas = await html2canvas(elementToScreenshot)
			const base64Image = canvas.toDataURL('image/jpeg')
			return base64Image
		},
		[]
	)
	const handleElementClick = useCallback(
		(event: MouseEvent) => {
			if (!selectEnabled) return
			event.preventDefault()
			event.stopPropagation()
			const element = event.target as HTMLElement
			if (element && element.style) {
				if (element.id !== 'select-elements') {
					element.style.border = '3px solid red'
					setSelectedElements([element])
					setSelectEnabled(false)
					captureScreenshotElement(element)
				}
			}
		},
		[selectEnabled, captureScreenshotElement]
	)

	// Function to manage adding/removing event listeners
	const manageEventListeners = useCallback(
		(action: 'add' | 'remove') => {
			const method =
				action === 'add'
					? document.addEventListener
					: document.removeEventListener
			method('mouseover', handleElementMouseOver, true)
			method('mouseout', handleElementMouseOut, true)
			method('click', handleElementClick, true)
		},
		[handleElementMouseOver, handleElementMouseOut, handleElementClick]
	)

	// useEffect to manage event listeners based on selectEnabled state
	useEffect(() => {
		if (selectEnabled) {
			manageEventListeners('add')
		} else {
			manageEventListeners('remove')
		}

		// Cleanup on unmount or when selectEnabled changes
		return () => {
			manageEventListeners('remove')
		}
	}, [selectEnabled, manageEventListeners])
	const { user } = useAstaAuth()

	const [formType, setFormType] = useState<
		'applicationBugReport' | 'astaFeedback'
	>('astaFeedback')
	const showSpecificReporter = useCallback(
		(type?: 'applicationBugReport' | 'astaFeedback') => {
			if (type) {
				setFormType(type)
			}
			showReporter()
		},
		[setFormType, showReporter]
	)

	const { data: githubConfig } = useQuery({
		queryKey: ['githubConfig'],
		queryFn: getGithubConfig,
	})

	const captureScreenshot = useCallback(async () => {
		const canvas = await html2canvas(document.body)
		const base64Image = canvas.toDataURL('image/jpeg')
		return base64Image
	}, [])

	const onInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const { name, value } = event.target
			issueFormActions.setField(name, value)
		},
		[issueFormActions]
	)

	const onInputChangeIssueNumber = useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const { value } = event.target
			issueFormActions.setIssueNumber(value)
			setLoadingIssueData(true)
		},
		[issueFormActions]
	)

	const onSelectChange = useCallback(
		(event: SelectChangeEvent<string>) => {
			const { name, value } = event.target
			issueFormActions.setField(name, value)
		},
		[issueFormActions]
	)

	const {
		data: projects,
		error: projectsError,
		isError: isProjectsError,
	} = useQuery({
		queryKey: ['projects'],
		queryFn: fetchProjects,
		enabled: !!githubConfig,
		retry: false, // Prevent infinite retry loops
	})

	const {
		data: fields,
		error: fieldsError,
		isError: isFieldsError,
	} = useQuery({
		queryKey: ['fields', projectIndex],
		queryFn: () => fetchProjectFields(projectIndex),
		enabled: !!projects && !!githubConfig, // Only fetch if projects are available
		retry: false, // Prevent infinite retry loops
	})

	const {
		data: issueData,
		error: issueError,
		isError: isIssueError,
	} = useQuery({
		queryKey: ['issueData', formData.issueNumber],
		queryFn: () => loadIssueData(formData.issueNumber),
		enabled: !!formData.issueNumber && !!githubConfig, // Only fetch if issueNumber is available
		retry: false, // Prevent infinite retry loops
	})

	useEffect(() => {
		if (projects && formData.projects.length === 0) {
			issueFormActions.setProjects(projects)
		}
	}, [projects, formData.projects.length, issueFormActions])

	useEffect(() => {
		if (fields && formData.fields.length === 0) {
			issueFormActions.setProjectFields(fields)
		}
	}, [fields, formData.fields.length, issueFormActions])

	useEffect(() => {
		if (issueData && formData.issueNumber && loadingIssueData) {
			issueFormActions.setField(
				'loadedTitle',
				issueData.loadedIssue.title
			)
			issueFormActions.setField(
				'loadedDescription',
				issueData.loadedIssue.body
			)
			issueFormActions.setProjectItemId(issueData.projectItemId)
			setLoadingIssueData(false)
		}
	}, [formData.issueNumber, issueData, issueFormActions, loadingIssueData])

	useEffect(() => {
		if (isProjectsError) {
			setFetchError({
				message: `Error fetching GitHub projects: ${(projectsError as Error).message}`,
				severity: 'error',
			})
		}
		if (isFieldsError) {
			setFetchError({
				message: `Error fetching GitHub project fields: ${(fieldsError as Error).message}`,
				severity: 'error',
			})
		}
		if (isIssueError) {
			// The user could be typing the issue number so we don't want to show an error
			setFetchError({
				message: `Could not load issue data (issue may have been deleted or incorrect number provided): ${(issueError as Error).message}`,
				severity: 'warning',
			})
		}
		// Clear the fetch error state if there are no errros
		if (!isProjectsError && !isFieldsError && !isIssueError) {
			setFetchError(null)
		}
	}, [
		isProjectsError,
		projectsError,
		isFieldsError,
		fieldsError,
		isIssueError,
		issueError,
	])

	const onModeSwitch = useCallback(() => {
		issueFormActions.toggleUpdateMode()
	}, [issueFormActions])

	const retryQueries = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['projects'] })
		queryClient.invalidateQueries({
			queryKey: ['fields', projectIndex],
		})
		queryClient.invalidateQueries({
			queryKey: ['issueData', formData.issueNumber],
		})
		setFetchError(null)
	}, [queryClient, formData.issueNumber])

	const captureAppContext = useCallback(async () => {
		const url = window.location.href
		const screenshot = await captureScreenshot()
		const environment = process.env.NODE_ENV?.toString() || 'unknown'
		const date = new Date().toLocaleString()
		const astaVersion = companionPackage.version // packageContext.version
		return {
			url,
			screenshot,
			environment,
			date,
			astaVersion,
		}
	}, [captureScreenshot])

	const captureUserContext = useCallback(() => {
		const browserVersion = getBrowserName(navigator.userAgent)
		const username = user?.email || 'unknown'
		return { browserVersion, username }
	}, [user?.email])

	const handleTestCaptureContext = useCallback(async () => {
		const appCtx = await captureAppContext()
		const userCtx = captureUserContext()
		setAppContext(appCtx)
		setUserContext(userCtx)
		issueFormActions.setScreenshot(appCtx.screenshot)
		issueFormActions.setIssueContext({
			appContext: appCtx,
			userContext: userCtx,
		})
	}, [captureAppContext, captureUserContext, issueFormActions])

	const contextValue = useMemo(
		() => ({
			issue,
			setIssue,
			pos,
			setPos,
			selectEnabled,
			setSelectEnabled,
			offset,
			setOffset,
			captureScreenshot,
			captureScreenshotElement,
			screenshotRef,
			selectedElements,
			setSelectedElements,
			onInputChange,
			onSelectChange,
			formData,
			issueFormActions,
			onInputChangeIssueNumber,
			onModeSwitch,
			startIndexProjectFields,
			endIndexProjectFields,
			snackbarOpen,
			openSnackbar,
			closeSnackbar,
			reporterVisible,
			showReporter,
			toggleReporter,
			hideReporter,
			issuePosition,
			fetchError,
			setFetchError,
			retryQueries,
			captureAppContext,
			captureUserContext,
			appContext,
			userContext,
			handleTestCaptureContext,
			isCompanion,
			setFormType,
			formType,
			showSpecificReporter,
			startHighlighting,
			stopHighlighting,
		}),
		[
			issue,
			pos,
			selectEnabled,
			offset,
			captureScreenshot,
			captureScreenshotElement,
			selectedElements,
			onInputChange,
			onSelectChange,
			formData,
			issueFormActions,
			onInputChangeIssueNumber,
			onModeSwitch,
			startIndexProjectFields,
			endIndexProjectFields,
			snackbarOpen,
			openSnackbar,
			closeSnackbar,
			reporterVisible,
			showReporter,
			toggleReporter,
			hideReporter,
			issuePosition,
			fetchError,
			retryQueries,
			captureAppContext,
			captureUserContext,
			appContext,
			userContext,
			handleTestCaptureContext,
			isCompanion,
			setFormType,
			formType,
			showSpecificReporter,
			setSelectEnabled,
			startHighlighting,
			stopHighlighting,
		]
	)

	return (
		<ReporterContext.Provider value={contextValue}>
			{children}
		</ReporterContext.Provider>
	)
}

export interface Project {
	id: string
	title: string
}

export interface FieldOption {
	id: string
	name: string
}

export interface Iteration {
	id: string
	startDate: string
}

export interface ConfigField {
	id: number
	name: string
	type: string
	placeholder?: string
	required: boolean
}

// This is the type of the field that we get from the GitHub API
export interface FormField {
	id: string
	name: string
	dataType?: string
	type: string
	placeholder?: string
	options?: FieldOption[]
	required?: boolean
	text?: string
	configuration?: {
		iterations?: Iteration[]
	}
}
