import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'

import {
	Box,
	FormControl,
	SelectChangeEvent,
	SnackbarOrigin,
	styled,
	TextField,
} from '@mui/material'

import formConfigData from '../../../public/formConfig.json'
import { CompanionForm } from './companionForm'
import RenderConfigurationField from './ConfigurationField'
import { onSubmit } from './formActions'
import { IssueState } from './IssueReporterReducer'
import { ConfigField, FormField, ReporterContext } from './ReporterContext'
import RenderSelectField from './SelectField'
import { ModeSwitchButton, SubmitButton } from './styledComponents'
import RenderTextArea from './TextArea'
import RenderTextField from './TextField'

const FormBox = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: '10px',
})

const divStyle = { marginBottom: '10px' }
export const fullWidth = { width: '100%' }
export const snackBarPosition: SnackbarOrigin = {
	vertical: 'bottom',
	horizontal: 'center',
}

export const renderConfigField = (
	field: ConfigField,
	fieldData: FormField,
	formData: IssueState,
	onInputChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void,
	onSelectChange: (event: SelectChangeEvent<string>) => void
): JSX.Element | null => {
	switch (field.type) {
		case 'textarea':
			// Used for description field
			return (
				<RenderTextArea
					field={field}
					errors={formData.errors}
					fieldData={fieldData}
					formData={formData}
					onInputChange={onInputChange}
				/>
			)
		case 'select':
			return (
				<RenderSelectField
					field={field}
					errors={formData.errors}
					fieldData={fieldData}
					formData={formData}
					onSelectChange={onSelectChange}
				/>
			)
		case 'configuration':
			return (
				<RenderConfigurationField
					field={field}
					errors={formData.errors}
					fieldData={fieldData}
					formData={formData}
					onSelectChange={onSelectChange}
				/>
			)
		// Default to be a simple text field
		default:
			return (
				<RenderTextField
					field={field}
					errors={formData.errors}
					fieldData={fieldData}
					formData={formData}
					onInputChange={onInputChange}
				/>
			)
	}
}

export interface FormConfig {
	name: string
	firstProjectFieldIndex: number
	fields: ConfigField[]
}

interface FormGeneratorProps {
	hideDiv: () => void
	isCompanion: boolean
}

// FormGenerator component
// This component is responsible for rendering the form
// Dynamically gets the fields from github and from the formConfig.json
// and handling the form submission
export const FormGenerator: React.FC<FormGeneratorProps> = ({
	hideDiv,
	isCompanion,
}) => {
	const context = useContext(ReporterContext)
	if (!context) {
		throw new Error('ReporterContext not found')
	}
	const {
		onSelectChange,
		onInputChange,
		onInputChangeIssueNumber,
		formData,
		issueFormActions,
		onModeSwitch,
		startIndexProjectFields,
		endIndexProjectFields,
		openSnackbar,
		setFetchError,
		showReporter,
		formType,
	} = context
	const [dynamicFormConfig, setDynamicFormConfig] =
		useState<FormConfig | null>(null)

	const safeDynamicFields = dynamicFormConfig?.fields || []
	const firstProjectFieldIndex =
		dynamicFormConfig?.firstProjectFieldIndex || 0

	useEffect(() => {
		if (!chrome?.storage?.sync) {
			// Assume the user is using the companion
			const formConfigs = formConfigData.forms
			const selectedForm = formConfigs.find(
				form => form.name === formType
			) as FormConfig
			if (selectedForm) {
				setDynamicFormConfig(selectedForm)
			} else {
				console.error(`Form with name ${formType} not found`)
				setFetchError({
					message: `Form with name ${formType} not found`,
					severity: 'error',
				})
			}
		} else {
			try {
				chrome.storage.sync.get(['formConfigFields'], config => {
					if (config.formConfigFields) {
						// there are two forms in the formConfigFields
						setDynamicFormConfig(
							config.formConfigFields.forms[0] as FormConfig
						)
					}
				})
			} catch (error) {
				if (error == 'Error: Extension context invalidated.') {
					setFetchError({
						message:
							'Extension context invalidated. Please refresh the page.',
						severity: 'error',
					})
				} else {
					console.error('Error fetching form config fields', error)
					setFetchError({
						message: 'Error fetching form config fields',
						severity: 'error',
					})
				}
			}
		}
	}, [formType, setFetchError])

	const loadedFields = () => {
		return (
			<>
				<FormControl fullWidth margin="normal">
					<TextField
						label="Issue Number"
						value={formData.issueNumber}
						onChange={onInputChangeIssueNumber}
					/>
				</FormControl>

				<FormControl fullWidth margin="normal">
					<TextField
						name="loadedTitle"
						label="Loaded Issue Title"
						value={formData.loadedTitle}
						onChange={onInputChange}
						required
					/>
				</FormControl>
				<FormControl fullWidth margin="normal">
					<TextField
						name="loadedDescription"
						label="Loaded Issue Description"
						value={formData.loadedDescription}
						onChange={onInputChange}
						required
						multiline
						rows={20}
					/>
				</FormControl>
			</>
		)
	}

	const memoizedOnSubmit = useCallback(
		(e: React.FormEvent) => {
			onSubmit(
				e,
				formData,
				startIndexProjectFields,
				endIndexProjectFields,
				openSnackbar,
				issueFormActions,
				hideDiv,
				showReporter
			)
		},
		[
			formData,
			startIndexProjectFields,
			endIndexProjectFields,
			openSnackbar,
			issueFormActions,
			hideDiv,
			showReporter,
		]
	)

	const CompanionFormComponent = (isUpdateMode: boolean) => {
		// Memoize fields safely
		const renderedFields = useMemo(() => {
			const customOptions = ['Epic', 'Feature', 'Improvement', 'Bug']
			const companionFields = safeDynamicFields.filter(field =>
				[
					'title',
					'description',
					'Value/Impact',
					'Requested By',
					'Type',
				].includes(field.name)
			)

			const filteredFields = isUpdateMode
				? companionFields.filter(
						field =>
							field.name !== 'title' &&
							field.name !== 'description'
					)
				: companionFields

			return filteredFields.map(field => (
				<div key={field.name} style={divStyle}>
					<CompanionForm
						field={field}
						formData={formData}
						onInputChange={onInputChange}
						onSelectChange={onSelectChange}
						customOptions={customOptions}
					/>
				</div>
			))
			// This does depend on these values, not sure why it marks it as exhaustive
			// if we dont have them then the form doesn't update correctly
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
			safeDynamicFields,
			formData,
			onInputChange,
			onSelectChange,
			isUpdateMode,
		])

		return (
			<>
				{isUpdateMode && (
					<>
						{/* Display loaded fields for update mode */}
						{loadedFields()}
					</>
				)}
				{/* Display the companion form fields */}
				{renderedFields}
			</>
		)
	}

	const renderedCompanionForm = CompanionFormComponent(formData.isUpdateMode)

	return (
		<form onSubmit={memoizedOnSubmit}>
			<ModeSwitchButton
				onClick={onModeSwitch}
				variant="contained"
				title={
					formData.isUpdateMode
						? 'Switch to posting a new issue'
						: 'Switch to updating an issue'
				}
			>
				{formData.isUpdateMode
					? 'Post New Issue'
					: 'Update Existing Issue'}
			</ModeSwitchButton>
			{formData.isUpdateMode && !isCompanion && loadedFields()}
			{isCompanion && renderedCompanionForm}
			{!formData.isUpdateMode &&
				!isCompanion &&
				safeDynamicFields.map(field => (
					<div key={field.name} style={divStyle}>
						{renderConfigField(
							field,
							formData.fields[field.id],
							formData,
							onInputChange,
							onSelectChange
						)}
					</div>
				))}
			{formData.isUpdateMode &&
				!isCompanion &&
				safeDynamicFields
					.slice(firstProjectFieldIndex, formData.fields.length - 1)
					?.map(field => (
						<div key={field.name} style={divStyle}>
							{renderConfigField(
								field,
								formData.fields[field.id],
								formData,
								onInputChange,
								onSelectChange
							)}
						</div>
					))}
			<FormBox>
				<SubmitButton type="submit" variant="contained" color="primary">
					Submit
				</SubmitButton>
			</FormBox>
		</form>
	)
}
