import { useReducer } from 'react'

import {
	bindCreatorsToDispatch,
	createAction,
	InferAction,
} from '@asta/react-component-library/src/state/index'
import {
	AgentEntry,
	AgentMessageType,
	LogLevel,
	LogType,
} from '@asta/run-artifacts'
import { RunLogEntry } from '@asta/run-artifacts/dist/esm/run-log/entry/run-log-entry'
import { produce } from 'immer'

import {
	getIssue,
	getProjectFields,
	getProjectItemId,
	getProjects,
	IssueResponse,
} from './reporter-api-caller'
import { FormField, Project } from './ReporterContext'
import { Issue } from './types'

export const templateDescription = `### Problem Description
<!--- Should provide sufficient detail including steps to reproduce the bug --->
Given <context>
When I <action>
Then I expected <result>
<!--- Optional.  Include only if not just the opposite of the expected result --->
But instead <result> 

### Context
<!--- Please provide information about where you observed the bug. Omit or delete fields
that aren't applicable. Provide additional context after the below fields as needed. --->
* ASTA instance: Dev or Prod
* application / variant: 
* run template: 
* run: 
* link: 

### Additional Information

### Screenshots
`

const constructDescription = (state: IssueState): string => {
	const logDetailsFormatted = state.logDetails
		.map(detail => `* ${detail.key}: ${detail.value}`)
		.join('\n')

	return `### Problem Description
<!--- Should provide sufficient detail including steps to reproduce the bug --->
Given <context>
When I <action>
Then I expected <result>
<!--- Optional.  Include only if not just the opposite of the expected result --->
But instead <result> 

### Steps to Reproduce
<!--- step, step... --->

### Additional Information

### Context
<!--- Please provide information about where you observed the bug. Omit or delete fields
that aren't applicable. Provide additional context after the below fields as needed. --->
* ASTA instance: ${state.appContext.environment}
${logDetailsFormatted}

### Log Context
* ASTA instance: ${state.appContext.environment}
${logDetailsFormatted}

**App Context:**
URL: ${state.appContext.url}
ASTA Version: ${state.appContext.astaVersion}
Date: ${state.appContext.date}
	
**User Context:**
Browser Version: ${state.userContext.browserVersion}
Username: ${state.userContext.username}
	
### Screenshots
`
}

export interface IssueState {
	title: string
	description: string
	screenshots: { url: string }[]
	projects: Project[]
	fields: FormField[]
	errors: { [key: string]: string }
	snackbarMessage: string
	snackbarSeverity: 'error' | 'success'
	snackbarOpen: boolean
	isUpdateMode: boolean
	projectItemId: string
	issueNumber: string
	loadedTitle: string
	loadedDescription: string
	runNumber: number
	entry: RunLogEntry | null
	logDetails: { key: string; value: string }[]
	appContext: {
		url: string
		screenshot: string
		environment: string
		date: string
		astaVersion: string
	}
	userContext: { browserVersion: string; username: string }
	[key: string]:
		| string
		| { url: string }[]
		| Project[]
		| FormField[]
		| { [key: string]: string }
		| boolean
		| RunLogEntry
		| number
		| { key: string; value: string }[]
		| null
}

export const actions = {
	setTitle: createAction<string, 'setTitle'>('setTitle'),
	setDescription: createAction<string, 'setDescription'>('setDescription'),
	setSeverity: createAction<string, 'setSeverity'>('setSeverity'),
	setScreenshot: createAction<string, 'setScreenshot'>('setScreenshot'),
	setField: createAction('setField', (name: string, value: string) => ({
		name,
		value,
	})),
	setIssue: createAction<Issue, 'setIssue'>('setIssue'),
	validateForm: createAction('validateForm'),
	formSubmissionSuccess: createAction('formSubmissionSuccess'),
	formSubmissionFailure: createAction<string, 'formSubmissionFailure'>(
		'formSubmissionFailure'
	),
	setSnackbar: createAction<
		{ message: string; severity: 'error' | 'success'; open: boolean },
		'setSnackbar'
	>('setSnackbar'),
	toggleUpdateMode: createAction('toggleUpdateMode'),
	resetErrors: createAction('resetErrors'),
	setProjects: createAction<Project[], 'setProjects'>('setProjects'),
	setProjectFields: createAction<FormField[], 'setProjectFields'>(
		'setProjectFields'
	),
	getState: createAction('getState'),
	setProjectItemId: createAction<string, 'setProjectItemId'>(
		'setProjectItemId'
	),
	setIssueNumber: createAction<string, 'setIssueNumber'>('setIssueNumber'),
	reset: createAction('reset'),
	setIssueContext: createAction<
		{
			appContext: {
				url: string
				screenshot: string
				environment: string
				date: string
				astaVersion: string
			}
			userContext: { browserVersion: string; username: string }
		},
		'setIssueContext'
	>('setIssueContext'),
	setFormState: createAction<IssueState, 'setFormState'>('setFormState'),
	setlogDetails: createAction<
		{ key: string; value: string }[],
		'setlogDetails'
	>('setlogDetails'),
	setRunNumber: createAction<number, 'setRunNumber'>('setRunNumber'),
	setEntry: createAction<RunLogEntry, 'setEntry'>('setEntry'),
}

const initialEntry: AgentEntry = {
	id: 0,
	type: LogType.Agent,
	level: LogLevel.Debug,
	timestamp: new Date().toISOString(),
	state: undefined,
	msg: '',
	data: {
		type: AgentMessageType.queue,
		selector: '',
	},
}

export type IssueAction = InferAction<(typeof actions)[keyof typeof actions]>

const reporterInitialState: IssueState = {
	title: '',
	description: templateDescription,
	screenshots: [],
	projects: [],
	fields: [],
	errors: {},
	snackbarMessage: '',
	snackbarSeverity: 'success',
	snackbarOpen: false,
	isUpdateMode: false,
	issueNumber: '',
	projectItemId: '',
	loadedTitle: '',
	loadedDescription: templateDescription,
	logDetails: [],
	runNumber: 0,
	entry: initialEntry,
	userContext: { browserVersion: '', username: '' },
	appContext: {
		url: '',
		screenshot: '',
		environment: '',
		date: '',
		astaVersion: '',
	},
}

const issueFormReducer = produce((state: IssueState, action: IssueAction) => {
	switch (action.type) {
		case actions.setTitle.type: {
			state.title = action.payload
			return
		}
		case actions.setDescription.type: {
			state.description = action.payload
			return
		}
		case actions.setScreenshot.type: {
			if (action.payload) {
				state.screenshots.push({ url: action.payload })
			}
			return
		}
		case actions.setField.type: {
			const {
				payload: { name, value },
			} = action
			state[name] = value
			return
		}
		case actions.setIssue.type: {
			const { title, description, screenshots } = action.payload
			state.title = title
			state.description = description
			state.screenshots = screenshots
			return
		}
		case actions.validateForm.type: {
			const newErrors: { [key: string]: string } = {}
			if (state.title == '') newErrors.title = 'Title is required'
			if (state.description == '')
				newErrors.description = 'Description is required'
			state.errors = newErrors
			return
		}
		case actions.formSubmissionSuccess.type: {
			state.snackbarMessage = 'Form submitted successfully'
			state.snackbarSeverity = 'success'
			return
		}
		case actions.formSubmissionFailure.type: {
			state.snackbarMessage = action.payload
			state.snackbarSeverity = 'error'
			return
		}
		case actions.setSnackbar.type: {
			const { message, severity, open } = action.payload
			state.snackbarMessage = message
			state.snackbarSeverity = severity
			state.snackbarOpen = open
			return
		}
		case actions.toggleUpdateMode.type: {
			if (state.isUpdateMode === false) {
				state.isUpdateMode = true
			} else {
				state.isUpdateMode = false
			}
			return
		}
		case actions.resetErrors.type: {
			state.errors = {}
			return
		}
		case actions.setProjects.type: {
			state.projects = action.payload
			return
		}
		case actions.setProjectFields.type: {
			state.fields = action.payload
			return
		}
		case actions.getState.type: {
			return state
		}
		case actions.setIssueNumber.type: {
			state.issueNumber = action.payload
			return
		}
		case actions.setProjectItemId.type: {
			state.projectItemId = action.payload
			return
		}
		case actions.reset.type: {
			state = reporterInitialState
			return state
		}
		case actions.setIssueContext.type: {
			const { appContext, userContext } = action.payload
			state.appContext = appContext
			state.userContext = userContext
			state.description = constructDescription(state)
			return
		}
		case actions.setlogDetails.type: {
			if (Array.isArray(action.payload)) {
				state.logDetails = action.payload

				// Reconstruct description with the updated log details
				state.description = constructDescription(state)
			}
			return
		}
		case actions.setRunNumber.type: {
			state.runNumber = action.payload
			return
		}
		case actions.setEntry.type: {
			state.entry = action.payload
			return
		}
		case actions.setFormState.type: {
			state = action.payload
			return
		}
		default:
			return state
	}
})

export { issueFormReducer, reporterInitialState }

export const useIssueFormReducer = (): [IssueState, typeof actions] => {
	const [state, dispatch] = useReducer(issueFormReducer, reporterInitialState)
	const boundActions = bindCreatorsToDispatch(dispatch, actions)

	return [state, boundActions] as const
}

export const fetchProjects = async (): Promise<Project[]> => {
	const projects = await getProjects()
	return projects
}

export const fetchProjectFields = async (
	projectIndex: number
): Promise<FormField[]> => {
	const fields = await getProjectFields(projectIndex)
	return fields
}

export const loadIssueData = async (
	issueNumber: string
): Promise<{ loadedIssue: IssueResponse; projectItemId: string }> => {
	const loadedIssue = await getIssue(issueNumber)
	const projectItemId = await getProjectItemId(loadedIssue.node_id)
	return { loadedIssue, projectItemId }
}
