import React, { useCallback, useContext, useEffect } from 'react'

import { ErrorBoundary } from '@asta/react-component-library'
import BugReportIcon from '@mui/icons-material/BugReport'
import { Box, Snackbar, Typography } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { styled } from '@mui/system'

import ReportIssueImage from '../../assets/reportIssue.svg?svgr'
import { DraggableButton } from './DraggableButton'
import { fullWidth, snackBarPosition } from './IssueWidget/FormGenerator'
import { IssueForm } from './IssueWidget/IssueReporter'
import { ReporterContext } from './IssueWidget/ReporterContext'
import { commonStyle } from './styles'

export const StyledDraggableButton = styled(DraggableButton)(({ theme }) => ({
	background: theme.palette.primary.dark,
	border: `2px solid ${theme.palette.primary.main}`,
	margin: theme.spacing(1),
	padding: theme.spacing(1),
	'&:hover': {
		backgroundColor: theme.palette.primary.light,
	},
}))
const Container = styled(Box)(() => ({
	...commonStyle,
	border: '0',
	position: 'fixed',
	bottom: 0,
	right: 0,
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
}))

const HiddenContainer = styled(Box)<{ visible: boolean }>(({ visible }) => ({
	display: visible ? 'block' : 'none',
}))

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
	function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
	}
)

interface WidgetProps {
	isCompanion: boolean
}

export const IssueWidget: React.FC<WidgetProps> = ({ isCompanion }) => {
	const context = useContext(ReporterContext)

	const openIssueReporter = useCallback(() => {
		if (context) {
			context.showSpecificReporter('astaFeedback')
			context.handleTestCaptureContext()
		}
	}, [context])

	useEffect(() => {
		if (isCompanion === false) {
			chrome.runtime.onMessage.addListener(request => {
				if (request.type === 'toggleIssueReporter' && context) {
					context.toggleReporter()
				}
			})
		}
	}, [context, isCompanion])

	if (!context) {
		console.error('Context is not available')
		return <Typography color="error">Context is not available</Typography>
	}

	const {
		snackbarOpen,
		closeSnackbar,
		formData,
		issuePosition,
		reporterVisible,
	} = context

	return (
		<>
			<Container>
				{!reporterVisible && (
					<StyledDraggableButton
						startingPosition={issuePosition}
						id="model-page"
						onClick={openIssueReporter}
						title="Report An Issue"
					>
						{isCompanion ? (
							<BugReportIcon />
						) : (
							<ReportIssueImage style={svgSx} />
						)}
					</StyledDraggableButton>
				)}
				<Snackbar
					open={snackbarOpen}
					autoHideDuration={6000}
					onClose={closeSnackbar}
					anchorOrigin={snackBarPosition}
				>
					<Alert
						onClose={closeSnackbar}
						severity={formData.snackbarSeverity}
						sx={fullWidth}
					>
						{formData.snackbarMessage}
					</Alert>
				</Snackbar>
			</Container>
			<HiddenContainer visible={reporterVisible}>
				<ErrorBoundary>
					<IssueForm isCompanion={isCompanion} />
				</ErrorBoundary>
			</HiddenContainer>
		</>
	)
}

const svgSx = {
	height: '30px',
	width: '30px',
} as const
