import React, { ReactNode } from 'react'

import { ErrorBoundary } from '@asta/react-component-library'
import { RunLogEntry } from '@asta/run-artifacts/dist/esm/run-log/entry/run-log-entry'

import { IssueWidget } from './Widget'

interface AppProps {
	isCompanion: boolean
	runNumber?: number
	entry?: RunLogEntry
	children?: ReactNode
}

export const ReporterApp: React.FC<AppProps> = ({ isCompanion, children }) => {
	return (
		<ErrorBoundary>
			<IssueWidget isCompanion={isCompanion} />
			{children}
		</ErrorBoundary>
	)
}
