// src/components/StyledButtons.ts
import { Button, styled } from '@mui/material'

export const CloseButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.light,
	color: theme.palette.primary.main,
	border: `1px solid ${theme.palette.primary.main}`,
	fontSize: '1.25rem',
	'&:hover': {
		backgroundColor: 'red',
		color: '#fff',
	},
}))

export const ModeSwitchButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	color: '#fff',
	border: `1px solid ${theme.palette.primary.main}`,
	fontSize: '.75rem',
	'&:hover': {
		backgroundColor: theme.palette.primary.dark,
		color: '#fff',
	},
}))

export const SubmitButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	color: '#fff',
	border: `1px solid ${theme.palette.primary.main}`,
	fontSize: '1.5rem',
	'&:hover': {
		backgroundColor: theme.palette.primary.dark,
		color: '#fff',
	},
}))
